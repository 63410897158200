import { initSwipe } from '../../utility/swipe';
import { show, hide } from '../../utility/hidden';

document.querySelectorAll(`.property-card__photos`).forEach(init);

function init(photosNode) {
  let prevNode = photosNode.querySelector('.property-card__control_type_prev');
  let nextNode = photosNode.querySelector('.property-card__control_type_next');
  let photoNodes = photosNode.querySelectorAll('.property-card__photo');
  let imageNodes = photosNode.querySelectorAll('img');

  if (!prevNode || !nextNode) return;

  let index = 0;

  initSwipe(photosNode, {
    forward: next,
    back: prev,
  });

  prevNode.addEventListener('click', prev);
  nextNode.addEventListener('click', next);

  function prev(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    hide(photoNodes[index]);
    index--;

    if (index === -1) index = photoNodes.length - 1;

    show(photoNodes[index]);

    if (!imageNodes[index].src) imageNodes[index].src = imageNodes[index].dataset.src;
    if (imageNodes[index - 1] && !imageNodes[index - 1].src) imageNodes[index - 1].src = imageNodes[index - 1].dataset.src;
  }

  function next(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    hide(photoNodes[index]);
    index++;

    if (index === photoNodes.length) index = 0;

    show(photoNodes[index]);

    if (!imageNodes[index].src) imageNodes[index].src = imageNodes[index].dataset.src;
    if (imageNodes[index + 1] && !imageNodes[index + 1].src) imageNodes[index + 1].src = imageNodes[index + 1].dataset.src;
  }
}
