let classname = `hidden`;
let regexp = new RegExp(` *${classname}`, `ig`);

export function hide(node) {
  node.className = node.className.replace(regexp, ``) + ` ${classname}`;
}

export function show(node) {
  node.className = node.className.replace(regexp, ``);
}

export function isHidden(node) {
  return node.className.indexOf(hiddenClassname) !== -1;
}

export const hiddenClassname = classname;

if (!window.FlexUtils) window.FlexUtils = {};

window.FlexUtils.hide = hide;
window.FlexUtils.show = show;
window.FlexUtils.isHidden = isHidden;
